@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.slick-slider{
    width: 100% !important;
    box-sizing: border-box;
    max-height: 100%;
}
.slick-slide{
    width: 100% !important;
    min-width: 100% !important;
    box-sizing: border-box;
}
.slick-list{
    width: 100% !important;
    box-sizing: border-box;
}
.slick-track{
    display: flex;
    box-sizing: border-box;
    width: 100% !important;
}
